import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'crimson';
import { DistributedTracingUtilService, OpenTelemetrySpan, SpanNames } from 'distributed-tracing';
import {
    AuthToken,
    ClientHeaderService,
    Configuration,
    HeaderNames,
    IdmService,
    OriginalServiceName,
    ReportRequestData,
    ServiceUseCaseName,
    UDSLaunchSource,
    UDSSuggestionData
} from 'gfn-shared';
import {
    HeaderType,
    NvHttpEndpoint,
    NvHttpEndpointFactoryService,
    NvRequestOptions,
    PropertyBag,
    RetryConfig
} from 'nv-angular-http-endpoint';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UdsSdkService {
    private udsEndpoint: NvHttpEndpoint;
    private logger: Logger;

    constructor(
        loggingService: LoggingService,
        private endpointFactory: NvHttpEndpointFactoryService,
        private distributedtracingUtilService: DistributedTracingUtilService,
        private idmService: IdmService,
        private headerService: ClientHeaderService,
        private appConfig: Configuration
    ) {
        this.logger = loggingService.getLogger('UdsSdkService');
        const {
            defaultRetries: maxRetries,
            defaultTimeout: timeout,
            exponentialBackoffMaxDelay: exponentialBackoffMaxDelay
        } = this.appConfig.uasConfig;

        const config: RetryConfig = {
            maxRetries,
            timeout,
            exponentialBackoffMaxDelay
        };
        this.udsEndpoint = this.endpointFactory.createEndpoint({ config });
    }

    private createRequestOptions(
        token: AuthToken,
        deviceId: string,
        serviceUseCase: ServiceUseCaseName,
        body: any = {},
        params: PropertyBag = {},
        includeFullResponse = false
    ): Observable<NvRequestOptions> {
        return this.createHeader(token, deviceId).pipe(
            map(headers => {
                const requestOptions: NvRequestOptions = {
                    headers: token ? headers : {},
                    params,
                    body,
                    reportRequestData: {
                        reportSuccessTelemetry: 'true',
                        originalService: OriginalServiceName.UDS,
                        serviceUseCase
                    },
                    includeRequestId: true,
                    includeFullResponse
                };
                return requestOptions;
            })
        );
    }

    private createHeader(token: AuthToken, deviceId: string): Observable<HttpHeaders | PropertyBag> {
        return this.headerService.getCommonHeaders({ withClientId: 'lcars' }).pipe(
            map(commonHeaders => {
                const headers = {
                    [HeaderType.authorization]: `Bearer ${token.token}`,
                    [HeaderNames.DeviceId]: deviceId
                };
                return Object.assign(headers, commonHeaders);
            })
        );
    }

    /**
     * Build end point url
     */
    private buildApiUrl(api: string): string {
        return this.appConfig.udsConfig?.server + '/v1/' + api;
    }

    public getSummonedUdsReport(
        reportRequestParams: ReportRequestData,
        parentSpan?: OpenTelemetrySpan,
        refreshForExpired = false
    ): Observable<UDSSuggestionData> {
        return this.idmService.getAuthToken(refreshForExpired, parentSpan).pipe(
            mergeMap((token: AuthToken) => {
                return this.createRequestOptions(
                    token,
                    reportRequestParams?.deviceId,
                    ServiceUseCaseName.UdsSummonedReport,
                    {},
                    { source: UDSLaunchSource.Mall, locale: reportRequestParams?.locale }
                );
            }),
            mergeMap(options => {
                const httpRequest = this.udsEndpoint.get<UDSSuggestionData>(
                    this.buildApiUrl('uds/session/reports'),
                    options
                );
                return this.distributedtracingUtilService.handleHttpEndpointSpan(
                    httpRequest,
                    options,
                    SpanNames.GetUdsSummonedReport,
                    parentSpan
                );
            }),
            catchError(error =>
                this.idmService.handleAuthError<UDSSuggestionData>(
                    error,
                    refreshForExpired,
                    this.getSummonedUdsReport.bind(this),
                    reportRequestParams,
                    parentSpan
                )
            )
        );
    }

    public getEndOfSessionUdsReport(
        body: any = {},
        deviceId: string,
        parentSpan?: OpenTelemetrySpan,
        refreshForExpired = false
    ): Observable<UDSSuggestionData> {
        return this.idmService.getAuthToken(refreshForExpired, parentSpan).pipe(
            take(1),
            mergeMap((token: AuthToken) => {
                return this.createRequestOptions(token, deviceId, ServiceUseCaseName.UdsEndOfSessionReport, body, {});
            }),
            mergeMap(options => {
                const httpRequest = this.udsEndpoint.post<UDSSuggestionData>(
                    this.buildApiUrl('uds/session/reports'),
                    options
                );
                return this.distributedtracingUtilService.handleHttpEndpointSpan(
                    httpRequest,
                    options,
                    SpanNames.GetUdsEndOfSessionReport,
                    parentSpan
                );
            }),
            catchError(error =>
                this.idmService.handleAuthError<UDSSuggestionData>(
                    error,
                    refreshForExpired,
                    this.getEndOfSessionUdsReport.bind(this),
                    body,
                    deviceId,
                    parentSpan
                )
            )
        );
    }
}
