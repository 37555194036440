import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Logger, LoggingService } from 'crimson';
import { interval, Observable, of, Subject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Configuration } from '../configuration/Configuration';
import { CampaignInfoEventType } from '../push-notification';
import { RefreshTriggerData, RefreshTriggerSource, RefreshUiService } from '../refresh-ui';
import { LcarsService } from './lcars-service';
import { CampaignsData, CampaignUIRefreshTriggers, ChangedCampaigns } from './Lcars.type';
import { isStreamerFlow, RouterService, Urls } from '../router';
import { LcarsSdkService } from './lcars-sdk.service';
import { BrowseUxService } from 'gfn-shared';

@Injectable({
    providedIn: 'root'
})
export class CampaignHelperService {
    private logger: Logger;
    private campaignIdToGameListMap = new Map<string, Set<string>>();
    private activeCampaigns = [];
    private deactiveCampaigns = [];
    private refreshCampaignUI = new Subject<CampaignUIRefreshTriggers>();
    refreshCampaignUI$ = this.refreshCampaignUI.asObservable();
    private removeGameFromCampaign = new Subject<string>();
    removeGameFromCampaign$ = this.removeGameFromCampaign.asObservable();
    private refetchGameCampaignIds = new Subject<void>();
    refetchGameCampaignIds$ = this.refetchGameCampaignIds.asObservable();
    private _allCampaignsInfo = null;
    private isStreamingOn = false;

    constructor(
        loggingService: LoggingService,
        private lcarsService: LcarsService,
        private router: Router,
        private refreshUiService: RefreshUiService,
        private routerService: RouterService,
        private appConfig: Configuration,
        private lcarsSdkService: LcarsSdkService,
        private browseUxService: BrowseUxService,
        private ngZone: NgZone
    ) {
        this.logger = loggingService.getLogger('CampaignHelperService');
        this.campaignIdToGameListMap = new Map();
    }

    initialize() {
        this.browseUxService.streamingBegin$.subscribe(
            _ => {
                this.logger.info('Received streamingBegin notification');
                this.isStreamingOn = true;
            },
            error => {
                this.logger.error('Error while receiving streamingBegin:', error);
                this.isStreamingOn = false;
            }
        );
        this.browseUxService.streamingTerminated$.subscribe(
            _ => {
                this.logger.info('Received streamingTerminated notification');
                this.isStreamingOn = false;
            },
            error => {
                this.logger.error('Error while receiving streamingTerminated:', error);
                this.isStreamingOn = false;
            }
        );

        this.ngZone.runOutsideAngular(() => {
            interval(this.appConfig.promoUI.refreshUICheckInterval)
                .pipe(tap(() => this.triggerCampaignUIRefresh(CampaignUIRefreshTriggers.TimerRefresh)))
                .subscribe();
        });

        this.refreshUiService.campaignDataRefresh$.subscribe(data => {
            if (this.appConfig.promoUI.isEnabled) {
                this.logger.info(
                    'Processing CampaignDataRefresh for',
                    data.source,
                    ' campaignInfo: ',
                    data.metadata?.campaignInfo
                );
                this.onCampaignDataRefresh(data);
            } else {
                this.logger.info('promo UI feature is disabled');
            }
        });
    }

    private onCampaignDataRefresh(data: RefreshTriggerData) {
        if (data?.metadata?.campaignInfo?.eventType) {
            switch (data?.metadata?.campaignInfo?.eventType) {
                case CampaignInfoEventType.CAMPAIGN_SCHEDULED:
                    this.fullCampaignUIRefresh();
                    break;
                case CampaignInfoEventType.CAMPAIGN_START:
                    if (
                        data?.metadata?.campaignInfo?.resourceIds.length > 0 &&
                        !this.areCampaignIdsPresent(data?.metadata?.campaignInfo?.resourceIds)
                    ) {
                        this.fullCampaignUIRefresh();
                    } else {
                        this.triggerCampaignUIRefresh(CampaignUIRefreshTriggers.CampaignsRefresh);
                    }
                    break;
                case CampaignInfoEventType.CAMPAIGN_COMPLETE:
                    this.triggerCampaignUIRefresh(CampaignUIRefreshTriggers.CampaignsRefresh);
                    break;
                case CampaignInfoEventType.CAMPAIGN_CANCELED:
                    this.lcarsService.refreshCampaignsInfoStream();
                    break;
                default:
                    this.logger.info('Unknown CampaignInfoEventType');
                    break;
            }
        } else if (data.source !== RefreshTriggerSource.OnPushNotification) {
            this.fullCampaignUIRefresh();
        }
    }

    private fullCampaignUIRefresh() {
        this.resetCampaignDataFlags();
        this.lcarsService.refreshCampaignsInfoStream();
        this.refetchGameCampaignIds.next();
    }

    private resetCampaignDataFlags() {
        // reset current activeCampaigns and deactiveCampaigns flags so force UI refresh happens on next CampaignUIRefreshTrigger
        this.activeCampaigns = [];
        this.deactiveCampaigns = [];
    }

    areCampaignIdsPresent(ids: string[]) {
        return ids?.every(id => this._allCampaignsInfo?.items?.find(item => item?.id === id));
    }

    addGameToCampaignId(campaignId: string, gameId: string) {
        if (this.campaignIdToGameListMap.has(campaignId)) {
            this.campaignIdToGameListMap.get(campaignId).add(gameId);
        } else {
            const gameList = new Set<string>();
            gameList.add(gameId);
            this.campaignIdToGameListMap.set(campaignId, gameList);
        }
    }

    removeGameFromCampaignId(campaignId: string, gameId: string) {
        const gameList = this.getGameListForCampaignId(campaignId);
        if (gameList?.has(gameId)) {
            this.logger.info('Game removed from campaign UI', gameId, campaignId);
            gameList.delete(gameId);
            this.removeGameFromCampaign.next(gameId);
        }
    }

    getCurrentCampaignIdsForGame(gameId: string): string[] {
        const currentCampaignIds = [];
        this.campaignIdToGameListMap.forEach((gameList: Set<string>, campaignId: string) => {
            if (gameList.has(gameId)) {
                currentCampaignIds.push(campaignId);
            }
        });
        return currentCampaignIds;
    }

    updateCampaignIdToGameListMap(newCampaignIds: string[], gameId: string) {
        const currentCampaignIds = this.getCurrentCampaignIdsForGame(gameId);
        const removedCampaignIds = currentCampaignIds?.filter(
            oldCampaignId =>
                !newCampaignIds?.some(newCampaignId => {
                    const newCampaignIdsWithoutVersion = newCampaignId.substring(0, newCampaignId.lastIndexOf('-'));
                    const oldCampaignIdWithoutVersion = oldCampaignId.substring(0, oldCampaignId.lastIndexOf('-'));
                    return newCampaignIdsWithoutVersion === oldCampaignIdWithoutVersion;
                })
        );
        removedCampaignIds?.forEach(removedCampaignId => {
            this.removeGameFromCampaignId(removedCampaignId, gameId);
        });
        if (newCampaignIds.length > 0) {
            newCampaignIds.forEach(campaignId => this.addGameToCampaignId(campaignId, gameId));
        }
    }

    getGameListForCampaignId(campaignId: string): Set<string> {
        return this.campaignIdToGameListMap.get(campaignId);
    }

    getChangedCampaigns(): Observable<ChangedCampaigns> {
        const newlyActivatedCampaigns = [];
        const newlyDeactivatedCampaigns = [];

        return this.lcarsService.getCampaignsInfo().pipe(
            take(1),
            map((allCampaignsInfo: CampaignsData) => {
                this.campaignIdToGameListMap.forEach((gameList: Set<string>, campaignId: string) => {
                    this._allCampaignsInfo = allCampaignsInfo;
                    const campaignInfo = allCampaignsInfo?.items?.find(item => item?.id === campaignId);
                    if (campaignInfo) {
                        const currentTimeStamp = this.lcarsSdkService.nvPreviewTime
                            ? new Date(this.lcarsSdkService.nvPreviewTime)
                            : new Date();
                        const startTime = new Date(campaignInfo.schedule.startTime);
                        const endTime = new Date(campaignInfo.schedule.endTime);
                        if (
                            startTime <= currentTimeStamp &&
                            endTime > currentTimeStamp &&
                            !this.activeCampaigns.includes(campaignInfo.id)
                        ) {
                            this.activeCampaigns.push(campaignInfo.id);
                            newlyActivatedCampaigns.push(campaignInfo);
                        } else if (endTime <= currentTimeStamp && !this.deactiveCampaigns.includes(campaignInfo.id)) {
                            this.deactiveCampaigns.push(campaignInfo.id);
                            newlyDeactivatedCampaigns.push(campaignInfo);
                        }
                    }
                });

                return {
                    activatedCampaigns: newlyActivatedCampaigns,
                    deactivatedCampaigns: newlyDeactivatedCampaigns
                };
            }),
            catchError(error => {
                this.logger.error('Error in getChangedCampaigns', error);
                return of({
                    activatedCampaigns: [],
                    deactivatedCampaigns: []
                });
            })
        );
    }

    triggerCampaignUIRefresh(campaignUIRefreshTrigger: CampaignUIRefreshTriggers) {
        if (!this.isStreamingOn) {
            if (campaignUIRefreshTrigger !== CampaignUIRefreshTriggers.TimerRefresh) {
                this.resetCampaignDataFlags();
            }
            this.refreshCampaignUI.next(campaignUIRefreshTrigger);
        }
    }
}
