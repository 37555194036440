export enum PluginNames {
    SystemInfo = 'SystemInfo',
    MessageBusRouter = 'MessageBusRouter',
    BackgroundProcess = 'BackgroundProcess',
    BackgroundAgent = 'BackgroundAgent',
    Gallery = 'Gallery',
    NetworkTest = 'NetworkTest',
    NvCamera = 'NvCamera',
    ShareServer = 'ShareServer'
}

export const MANDATORYPLUGINMODULES: string[] = [
    PluginNames.SystemInfo,
    PluginNames.MessageBusRouter,
    PluginNames.BackgroundAgent,
    PluginNames.BackgroundProcess
];
export const OPTIONALPLUGINMODULES: string[] = [
    PluginNames.Gallery,
    PluginNames.NetworkTest,
    PluginNames.NvCamera,
    PluginNames.ShareServer
];

export const TimeOutForMandatoryPlugins = 60000;
