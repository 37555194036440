import { BannerNotificationData } from 'banner-notification';
import { BannerNotificationTypes } from 'gfn-shared';

export const OSUnsupportedBannerData: BannerNotificationData = {
    notificationType: BannerNotificationTypes.UnSupportedOS,
    icon: 'info',
    messageStr: { key: 'notification.unsupportedOSNotificationBanner' },
    primaryButtonText: 'common.learnMore',
    secondaryButtonText: 'notification.dismiss'
};
