import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HigModule } from 'hig';
import { BannerNotificationComponent } from './components/banner-notification/banner-notification.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, TranslateModule, HigModule, MatButtonModule, MatIconModule],
    declarations: [BannerNotificationComponent],
    exports: [BannerNotificationComponent]
})
export class BannerNotificationModule {}
