<div class="container">
    <button
        mat-flat-button
        class="action-btn font-button2 mat-accent"
        (click)="execRightButtonAction()"
        cdkFocusInitial
    >
        {{ rightButtonText | translate | uppercase }}
    </button>
    <button mat-button *ngIf="showButton" class="font-button2 back-hover-override" (click)="execLeftButtonAction()">
        {{ leftButtonText | translate | uppercase }}
    </button>
</div>
